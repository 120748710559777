$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

.form-control {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 1px solid $baseColor3;
    margin-bottom: 1rem;

    // color: $baseColor4;

    &::placeholder {
        color: rgba(255, 255, 255, 0.3);    
    }

    &:active, &:focus {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: none;
        border-bottom: 1px solid $baseColor3;
    }
}