$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);
$titleLineColor: $baseColor3;

.b2b-package-deposit {
    width: 100%;
    background-color: $primaryTextColor;
    color: $baseColor4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;

    padding: 120px 1rem 2.5rem;
    // padding: 60px 1rem 2.5rem;

    .deposit-title {
        font-size: 3rem;
        margin-bottom: 2rem;
        border-bottom: 5px solid $titleLineColor;
        color: $baseColor4;
        padding-bottom: 0.6rem;
        text-align: center;

        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .deposit-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 1rem;
        
        width: 100%;

        .deposit-description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;

            .description-mascot {
                width: 400px;

                img {
                    width: 100%;
                }
            }

            .description-text {
                font-size: 24px;
                text-align: center;
                // width: 80%;
            }
        }

        .deposit-pricing {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-evenly;
            gap: 15px;

            width: 60%;

            .deposit-column {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                font-size: 20px;
                font-weight: 400;

                &:first-child {
                    align-items: flex-start;
                    font-weight: 700;

                    .deposit-column-item {                    
                        text-align: left;
                    }
                }

                .deposit-column-item {
                    padding: 15px 20px;
                    text-align: center;

                    .highlighted {
                        text-decoration: underline;
                        color: $baseColor3;
                    }

                    &:first-child {
                        font-weight: 700;
                        text-align: center;

                        height: 100px;
                    }
                }

                &:last-of-type {
                    
                    .deposit-column-item {
                        background-color: $baseColor3;
                        color: $primaryTextColor;
                        font-weight: 700;
                        width: 100%;

                        &:first-child {
                            background-color: transparent;
                            color: $baseColor4;
                        }

                        &:nth-child(2) {
                            border-radius: 20px 20px 0 0;
                        }

                        &:nth-child(5) {
                            border-radius: 0 0 20px 20px;
                        }
                    }
                    
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .deposit-content {
            flex-direction: column;

            .deposit-description {
                width: 100%;

                .description-text {
                    text-align: center;
                }
            }

            .deposit-pricing {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        .deposit-content {
            .deposit-description {
                .description-mascot {
                    width: 300px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .deposit-title {
            border-bottom: none;
        }

        .deposit-content {
            .deposit-description {
                .description-text {
                    font-size: 28px;
                }
            }

            .deposit-pricing {
                width: 100%;
                gap: 0;
                
                .deposit-column {
                    font-size: 12px;

                    .deposit-column-item {
                        &:first-child {
                            // height: 120px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        padding: 60px 1rem 2.5rem;

        .deposit-title {
            font-size: 2rem;
            margin-bottom: 0;
        }

        .deposit-content {
            .deposit-pricing {
                overflow-x: scroll;

                .deposit-column {
                    .deposit-column-item {
                        text-wrap: nowrap;
                    }
                }
            }

            .deposit-description {
                padding: 50px 0 20px;
                gap: 15px;

                .description-text {
                    font-size: 1.5rem;
                }
            }
        }
    }
}