$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);
$titleLineColor: $baseColor3;

.b2b-package-benefit {
    width: 100%;
    background-color: $baseColor2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3em;

    padding: 120px 0 2.5rem;

    .benefit-title {
        font-size: 3rem;
        margin-bottom: 2rem;
        border-bottom: 5px solid $titleLineColor;
        color: $baseColor4;
        padding-bottom: 0.6rem;
        text-align: center;

        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .benefit-list {
        display: grid;
        grid-template-columns: minmax(400px,1fr) minmax(400px,1fr);
        width: 100%;
        gap: 25px;

        padding: 0 4rem;

        .benefit-item {
            background-color: $baseColor4;
            color: $baseColor;

            padding: 1.5rem 2rem;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            border: 5px solid $baseColor3;
            border-radius: 20px;

            // .benefit-item-asset {
            //     min-width: 150px;
            //     max-width: 200px;

            //     img {
            //         width: 100%;
            //     }
            // }

            .benefit-item-content {
                margin-left: 20px;

                .benefit-item-title {
                    font-size: 1.3rem;
                    font-weight: bold;
                }
            }
        }
    }

    .benefit-notes {
        align-self: flex-start;
        margin-left: 4rem;

        &-item {
            display: block;
            font-size: 13px;
            color: $baseColor4;
        }
    }
}

@media (max-width: 970px) {
    .b2b-package-benefit {
        .benefit-title {

        }

        .benefit-list {
            grid-template-columns: 1fr;
        }
    }
}

@media (max-width: 768px) {
    .b2b-package-benefit {
        .benefit-title {
            width: fit-content;
            text-align: center;
            border: 0;
            font-size: 2.5rem;
        }

        .benefit-list {            
            padding: 0 2rem;
        }

        .benefit-notes {
            margin-left: 1rem;
            padding: 0 1rem;
        }
    }
}

@media (max-width: 500px) {
    .b2b-package-benefit {
        gap: 1rem;

        .benefit-title {
            margin-bottom: 1rem;
        }

        .benefit-list {
            padding: 1rem;
        }
    }
}