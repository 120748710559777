$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$darkTextColor: var(--primary-text-color);
$contentBackgroundColor: var(--white-color);

$textColor: $baseColor4;
$titleLineColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);

.testimony {
    width: 100%;
    // background-color: rgb(246, 246, 246);
    background-color: $contentBackgroundColor;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3em;

    padding: 120px 0 2.5rem;

    .testimony-title, .testimony-item-title {
        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .testimony-title {
        font-size: 3rem;
        margin-bottom: 2rem;
        border-bottom: 5px solid $titleLineColor;
        color: $baseColor1;
        padding-bottom: 0.6rem;
        text-align: center;
    }

    @media screen and (max-width: 670px) {
        .testimony-title {
            padding: 0 1rem;
            // border: 0;
            width: fit-content;
            // font-size: 2.5rem;
            margin-bottom: 1rem;
        }

        // padding: 2.5rem 0 1rem;
        padding: 120px 0 1rem;
    }

    @media screen and (max-width: 600px) {
        .testimony-title {
            border: none;
        }
    }

    .testimony-loading-section {
        width: 100%;
        padding: 0.8rem 0rem 2.5rem;
        margin: 0.6rem 0 2rem;

        position: relative;

        .spinner-grow, .spinner-loading {
            background-color: $baseColor4;
        }
    }

    .testimony-item-container {
        width: 100%;
        padding: 0.8rem 0rem 2.5rem;
        margin: 0.6rem 0 2rem;
        min-height: 200px;
        display: flex;
        justify-content: center;
        // overflow: visible;

        .testimony-item-dot-list {
            
            .react-multi-carousel-dot--active button{
                background-color: $baseColor1;
            }

            li button {
                background-color: $baseColor2;
                display: none;
            }
        }
    
        .testimony-item-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            // gap: 20px;
    
            .testimony-item {
                background-color: $baseColor4;            
                // background-color: $contentBackgroundColor;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
    
                position: relative;
    
                min-width: 350px;
                width: 60%;
                min-height: 200px;
                
                border: 1px solid $baseColor3;
                border-radius: 30px;
                
                padding: 1rem;

                .quote-icon {
                    width: 60px;
                    position: absolute;
                    left: 0;
                    top: -15px;

                    img {
                        width: 100%;
                    }
                }
    
                .reviewer-name {
                    background: $baseColor1;
                    color: $baseColor4;
                    padding: 0.2rem 0;
                    width: 100px;
                    font-size: 24px;

                    text-align: center;

                    border-radius: 10px;

                    position: absolute;
                    top: -15px;
                }

                .review-text {
                    font-size: 22px;
                    text-align: center;
                }
                
                .review-rating {
                    background-color: $baseColor2;
                    padding: 0.4rem;
                    border-radius: 10px;

                    position: absolute;
                    bottom: -15px;

                    .rating-star {
                        color: $baseColor3;
                    }
                }

            }

            @media screen and (max-width: 1180px) {
                .testimony-item {
                    min-width: 0;
                    max-width: 100%;
                    width: 80%;
                    
                    padding: 2rem 1rem;
                }
            }
        }

        @media screen and (max-width: 800px) {
            justify-content: flex-start;
        }
    }
}