$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

.service-page {
    .b2b-section {
        display: flex;

        .business-entity {
            transition: all 0.3s ease;
            
            &:hover {
                z-index: 99;
                box-shadow: 3px 0 5px $baseColor;
            }
        }

        .b2b-package {
            transition: all 0.3s ease;
            
            &:hover {
                z-index: 99;
                box-shadow: -3px 0 5px $baseColor;
            }
        }

        @media ( max-width: 1000px ) {
            flex-direction: column-reverse;
        }
    }
}