$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);
$titleLineColor: $baseColor3;

.b2b-package-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
    
    .b2b-package-title-container {
        .header-content-title {
            font-weight: 800;
            font-size: 3.5rem;
            // color: #edc953;
            color: $primaryTextColor;
            font-family: 'Ubuntu';
            margin-bottom: 20px;
            
            text-shadow: 3px 3px 2px rgba(95, 157, 247, 1);
            
            max-width: 700px;
            text-align: center;

        }
    }

    
    .b2b-package-header-content {
        font-family: 'Ubuntu';
        // width: 60%;
        padding: 1rem 0.5rem;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        
        .package-content-first-row {
            display: flex;
            align-items: center;
            gap: 30px;

            .b2b-package-header-asset {
                width: 40%;
                min-width: 200px;
        
                img {
                    width: 100%;
                }
            }
    
            .header-content-description {
                font-size: 1.5rem;
                font-weight: 500;
                width: 85%;
    
                .highlighted {
                    font-size: 1.7rem;
                }
            }
        }

        .package-content-second-row {
            width: 100%;

            .header-content-cta-container {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                gap: 2rem;
                width: 100%;
                
                .header-content-cta-button {
                    width: 100%;
                    // max-width: 500px;
                    margin-top: 50px;
    
                    text-decoration: none;
                    border: none;
                    outline: none;
    
                    background: $baseColor;
                    background: linear-gradient(150deg, $baseColor 0%, $baseColor2 100%);
                    color: $baseColor4;
    
                    padding: 1rem;
    
                    font-size: 20px;
                    border-radius: 30px;
                    
                    text-align: center;
    
                    &:hover, &:focus, &:active {
                        // color: #12171a;
                        // background-position: 500px;
                    }
                }
    
                @media (max-width: 1200px) {
                    flex-direction: column;
                    gap: 0rem;
    
                    .header-content-cta-button {
                        width: 100%;
                    }
                }
            }
        }

    }
}


@media (max-width: 970px) {
    .b2b-package-header {
        .b2b-package-header-asset {
            min-width: 350px;
        }

        .b2b-package-header-content {
            .header-content-title {
                font-size: 3rem;
            }

            .header-content-description {
                font-size: 1.25rem;

                .highlighted {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .b2b-package-header {
        flex-direction: column;

        .b2b-package-header-asset {
            width: 50%;
            display: flex;
            justify-content: center;
        }
        
        .b2b-package-header-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 0 3rem;

            .package-content-first-row {
                flex-direction: column;

                .header-content-description {
                    text-align: center;
                }
            }

            .header-content-title,
            .header-content-description {
                // text-align: center;
            }

            .notes {
                max-width: initial;
            }

            .header-content-cta-button {
                width: 100%;
            }
        }

    }
}

@media (max-width: 576px) {
    .b2b-package-header {
        padding: 0;
        padding-top: 5rem;

        .b2b-package-title-container {
            .header-content-title {
                font-size: 2rem;
            }
        }

        .b2b-package-header-content {
            padding-bottom: 0;

            .package-content-first-row {
                padding: 2rem 2rem 1rem;

                .header-content-description {
                    width: 90%;
                    font-size: 1.3rem;

                    .highlighted {
                        font-size: 1.3rem;
                    }
                }
            }

            .package-content-second-row {
                .header-content-cta-container {
                    flex-direction: row;
                    justify-content: flex-start;

                    .header-content-cta-button {
                        border-radius: 30px 30px 0 0;
                        padding: 10px;
                        font-weight: 600;
                        font-family: 'Ubuntu';
                        font-size: 14px;

                        &.deposit {
                            background: $primaryTextColor;
                        }

                        &.full-service {
                            background: $baseColor;
                        }

                        &.legal-boost {
                            background: $baseColor2;
                        }
                    }
                }
            }
        }
    }
}