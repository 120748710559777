$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$primaryTextColor: var(--primary-text-color);

.background {
    display: flex;
    position: relative;
    min-height: 600px;
    height: 100vh;

    .background-fixed-content {
        position: absolute;
        width: 100%;
        height: 100%;
        // top: 30px;
        top: 8vw;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // padding: 50px 15px;

        .background-fixed-title {
            color: #000;
            background-color: $baseColor4;
            border: 1px solid #000;
            width: fit-content;
            padding: 5px 15px;
            border-radius: 10px;
            font-weight: 700;
            font-family: 'Ubuntu';
            font-size: 3rem;
        }

        .background-fixed-mascot {
            max-width: 500px;
        }
    }

    .background-box {
        transition: all 0.3s ease;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .background-box-icon {
            width: 100%;
            max-width: 150px;
        }

        .background-box-text {
            font-size: 2rem;
            text-align: center;
            font-weight: 700;
            max-width: 350px;

            .changable-text {
                display: block;
            }
        }
     
        &#box-one {
            background-color: $baseColor3;

            &:hover {
                // z-index: 99;
                box-shadow: 3px 0 5px $baseColor;
            }

            .background-box-text {
                color: $baseColor;

                .changable-text {
                    color: $primaryTextColor;

                    &::before {
                        content: "Ketenagakerjaan";
                        animation: changeTextFirst 4.5s 0s infinite;
    
                        /*
                            List 1:
                            -Ketenagakerjaan
                            -Bisnis
                            -Fintech
                            -Utang Piutang
                            -HAKI
                            -Properti
                            -Perkawinan
                            -Perceraian
                            -Keluarga
                        */
                        @keyframes changeTextFirst {
                            0% {
                                content: "Ketenagakerjaan";
                            }
                            13% {
                                content: "Bisnis";
                            }
                            26% {
                                content: "Fintech";
                            }
                            39% {
                                content: "Utang Piutang";
                            }
                            52% {
                                content: "HAKI";
                            }
                            65% {
                                content: "Properti";
                            }
                            78% {
                                content: "Perkawinan";
                            }
                            91% {
                                content: "Perceraian";
                            }
                            100% {
                                content: "Keluarga";
                            }
                        }
                    }
                }
            }
        }
        
        &#box-two {
            background-color: $baseColor2;

            &:hover {
                // z-index: 99;
                box-shadow: -3px 0 5px $baseColor;
            }

            .background-box-text {
                color: $baseColor4;

                .changable-text {
                    color: $baseColor3;

                    &::before {
                        content: "Kepercayaan akan";
                        animation: changeTextSecond 3.0s 0s infinite;
    
                        /*
                            List 2:
                            -Kepercayaan akan
                            -Transparansi Harga
                            -Perhatian atas
                            -Aksesibilitas ke
                            -Kesadaran akan
                            -Kejelasan Informasi
                        */
                        @keyframes changeTextSecond {
                            0% {
                                content: "Kepercayaan akan";
                            }
                            20% {
                                content: "Transparansi Harga";
                            }
                            40% {
                                content: "Perhatian atas";
                            }
                            60% {
                                content: "Aksesibilitas ke";
                            }
                            80% {
                                content: "Kesadaran akan";
                            }
                            100% {
                                content: "Kejelasan Informasi";
                            }
                        }
                    }
                }
            }
        }
    }

    @media ( max-width: 1400px ) {
        .background-fixed-content {
            justify-content: flex-start;
            // padding: 170px 0;
            gap: 30px;

            .background-fixed-title {
                font-size: 2rem;
            }

            .background-fixed-mascot {
                max-width: 400px;
            }
        }
    }

    @media ( max-width: 1125px ) {
        .background-fixed-content {

            .background-fixed-title {
                font-size: 2rem;
            }

            .background-fixed-mascot {
                max-width: 350px;
            }
        }

        .background-box {
            .background-box-icon {
                
            }

            .background-box-text {
                font-size: 1.7rem;
                max-width: 300px;
            }
        }
    }

    @media ( max-width: 1100px ) {
        flex-direction: column-reverse;

        .background-fixed-content {
            padding: 0;
            top: 47%;
            left: 20%;
            right: 20%;
            height: fit-content;
            width: fit-content;

            .background-fixed-title {
                // font-size: 1.5rem;
            }

            .background-fixed-mascot {
                display: none;
            }
        }

        .background-box {
            height: 100%;
            // .background-box-icon {
                
            // }

            // .background-box-text {
            //     font-size: 2rem;
            //     max-width: 300px;
            // }
        }
    }

    @media ( max-width: 768px ) {
        .background-fixed-content {
            .background-fixed-title {
                font-size: 1.5rem;
            }
        }
    }

    @media ( max-width: 576px ) {
        .background-fixed-content {
            left: 10%;
            right: 10%;
            
            .background-fixed-title {
                // font-size: 1.5rem;
            }
        }
    }
}