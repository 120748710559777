$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

.business-entity-page {
    padding-top: 100px;

    .business-entity-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 2rem;
    
        background: linear-gradient(40deg, $baseColor2 50%, $baseColor1 100%);
    
        padding: 150px 0 2rem;
    
        .business-entity-page-title {
            color: $baseColor4;
            border-bottom: 5px solid $baseColor3;
            font-size: 3rem;
            font-weight: 700;
    
            position: relative;
    
            text-align: center;
    
            margin: 0 2rem 1.5rem;
    
            &:after {
                content: 'Bekerja sama dengan Pribadi Randa & Partners Law Firm';
                position: absolute;
                width: 100%;
                text-align: center;
    
                font-size: 16px;
                font-style: italic;
    
                left: 0;
                bottom: -37px;
            }
    
            @media (max-width: 600px) {
                border-bottom: 0;
            }
        }
    
        .business-entity-loading-container {
            position: relative;
    
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            
            gap: 3rem;
            width: 100%;
            min-height: 500px;
    
            padding: 0 2rem;
        }
    }
}