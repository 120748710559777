$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);
$backgroundColor: var(--base-color-shade);

.article-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;

    padding: 150px 2rem 2rem;

    background-color: $baseColor2;

    .article-page-title {
        border-bottom: 5px solid $baseColor3;
        font-weight: 700;
        color: $baseColor4;
    }
}