$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$baseColorShade1: var(--base-shade-color);

$contentBackgroundColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);

$titleLineColor: $baseColor3;

.advantages {
    width: 100%;
    background: $contentBackgroundColor;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    // gap: 3em;

    .advantage-title, .advantage-item-title {
        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .advantage-header-container {
        width: 100%;

        // background: $baseColor3;
        // background: linear-gradient(120deg, $baseColor4 5%, $baseColor3 100%);
        // background-color: $baseColor;    
        padding: 80px 0 40px 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .advantage-title {
        font-size: 3rem;
        margin: 50px 2rem;

        text-align: center;
        // border-bottom: 5px solid $titleLineColor;
        // padding-bottom: 0.6rem;

        background-color: $baseColor;    
        padding: 15px 45px;
        border-radius: 20px;

        width: max-content;
        // color: $baseColor;
        color: $baseColor4;

        @media screen and (max-width: 670px) {
            width: fit-content;
            text-align: center;
            // border-bottom: none;

            font-size: 2.5rem;
        }

        @media screen and (max-width: 517px) {
            border-bottom: none;
        }
    }

    .advantage-grid-section {
        display: grid;
        grid-template-columns: 50% 50%;

        padding: 1.5rem 0;

        .advantage-item {
            // width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            padding: 0.5rem 0;

            // background: linear-gradient(150deg, $baseColor 20%, $baseColor2 100%);
            // background-color: $baseColorShade1;
            background-color: $contentBackgroundColor;
            color: $primaryTextColor;

            .advantage-item-title {
                color: $primaryTextColor;
                // font-size:
            }
            // &:nth-of-type(odd) {
            // }

            // &:nth-of-type(even) {
            // }
            
            flex-direction: row;
            
            .advantage-item-illustration {
                grid-area: illustration;
                max-width: 160px;

                img {
                    width: 100%;
                }
            }

            .advantage-item-content {
                padding: 0rem 2rem;
                grid-area: content;
                width: 60%;
                
                .advantage-item-title {
                    font-size: 1.7rem;
                    margin-bottom: 0.6rem;
                    word-break: break-word;
                }
                
                .advantage-item-desc {
                    font-size: 1.2rem;

                    a {
                        color: inherit;
                        transition: all 0.1s ease;
                        // text-decoration: none;

                        &:hover, &:active, &:focus {
                            // color: $baseColor3;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1050px) {
            grid-template-columns: 100%;
        }

        @media screen and (max-width: 768px) {
            .advantage-item,
            .advantage-item.advantage-item-reverse {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                // .advantage-item-illustration {
                //     max-width: 100px;
                // }

                .advantage-item-content {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    @media (max-width: 576px) {
        .advantage-title {
            font-size: 2rem;
            margin: 50px 2rem 20px;
        }

        .advantage-header-container {
            padding: 0;
        }
    }
}