$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$backgroundColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);

.business-entity {
    background-color: $backgroundColor;
    color: $baseColor1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 10px;

    min-height: 400px;
    padding: 4rem 0;

    width: 50%;

    .business-entity-header {
        
        h2 {
            font-size: 3rem;
            font-weight: 700;
            width: 80%;
            margin: 0 auto;
            text-align: center;

            margin-bottom: 40px;
        }
    }

    .business-entity-assets {
        width: 80%;
        max-width: 600px;

        img {
            width: 100%;
        }
    }

    .business-entity-button-container {
        .btn-business-entity {
    
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        
            text-decoration: none;
            // color: #12171a;
            color: $baseColor4;
            font-weight: 700;
            background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
            // background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
        
            font-size: 18px;
        
            padding: 1rem 2rem;
            border-radius: 40px;
        
            .btn-consult-icon {
                font-size: 25px;
                width: 30px;
            }
        
            transition: all 0.5s ease-in-out;
            
            &:hover, &:focus, &:active {
                // background-position: 275px;
                background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                color: $primaryTextColor;
            }
        }
    }

    @media ( max-width: 1000px ) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .business-entity-header {
            h2 {
                font-size: 2.7rem;
            }
        }

        .business-entity-button-container {
            width: 80%;

            .btn-business-entity {
                font-size: 20px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .business-entity-header {
            h2 {
                font-size: 2.2rem;
            }
        }
    }
}