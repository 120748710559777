$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$darkBlue: var(--base-dark-blue-color);

$headerBackgroundImage: var(--header-background-image);

.header {
    // background: $contentBackgroundColor;
    
    width: 100%;
    height: 100%;
    // min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;
    
    // background-image: $headerBackgroundImage;
    background-position: center;
    background-size: cover; 

    .header-content {
        background-color: rgba(255, 255, 255, 0);
        padding: 150px 0 0;
        // background: linear-gradient(150deg, $primaryTextColor 10%, $baseColor2 100%);
        // background-color: rgba(#5F9DF7, 1);
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 30px;

        .header-logo {
            min-width: 400px;
            width: 35%;
            
            img {
                width: 100%;
                // max-width: 500px;
            }

        }

        .header-title-container {
            font-family: 'Ubuntu';

            width: 50%;
            padding-left: 50px;
            position: relative;

            // background-color: black;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .header-title {
                font-weight: 800;
                font-size: 3.5rem;
                // color: #edc953;
                color: $primaryTextColor;
                font-family: 'Ubuntu';
                
                .highlighted {
                    color: $baseColor;
                }
            }

            .header-tagline {
                font-size: 2rem;
                font-weight: 500;
                font-style: italic;

                * {
                    display: block;
                }

                small {
                    font-size: 1rem;
                }
            }

            .header-cta-button-container {
                display: flex;
                // align-items: center;
                justify-content: flex-start;
                gap: 20px;
                width: 100%;

                .header-cta-button {
                    width: 100%;
                    // max-width: 600px;
                    margin-top: 20px;
    
                    &:hover, &:focus, &:active {
                        // color: #12171a;
                        // background-position: 500px;
                    }
                }
            }

            .inline-mobile {
                display: block;
            }

            .notes {
                font-family: 'JosefinSans';
                font-weight: 700;
                // text-align: center;

                color: #235910;

                font-size: 16px;

                // max-width: 500px;

                .underline {
                    text-decoration: underline;
                    text-decoration-color: #dca43c;
                }
            }

            @media screen and (max-width: 750px) {
                width: 100%;
                padding: 0.5rem 0;
                text-align: center;

                .header-title {
                    font-size: 4rem;
                }

                .header-tagline {
                    font-size: 1.7rem;
                }

                .notes {                
                    padding: 0 2.5rem;
                }
            }
        }
        
        &.reversed {
            flex-direction: row-reverse;

            .header-title-container {
                // align-items: flex-end;

                .header-title,
                .header-tagline {
                    text-align: right;
                }

                .header-cta-button-container {
                    justify-content: flex-end;

                    .header-cta-button {
                        //
                    }
                }

                .notes {
                    // text-align: center;
                    width: 100%;
                    padding: 0 20px;
                    align-self: 'flex-start';
                }
            }
        }

        @media screen and (max-width: 890px) {
            .header-title-container {
                .header-cta-button-container {
                    flex-direction: column;
                }
            }
        }

        @media screen and (max-width: 880px) {
            .header-logo {
                min-width: 350px;
            }

            .header-title-container {
                .header-title {
                    font-size: 3rem;
                }

                .header-tagline {
                    font-size: 1.75rem;
                }
            }
        }
        
        @media screen and (max-width: 576px) {
            .header-logo {
                width: 50%;
            }

            .header-title-container {

                .header-title {
                    font-size: 2.5rem;
                }

                .header-tagline {
                    font-size: 1.5rem;
                }

                .header-cta-button-container {
                    width: 90%;

                    .header-cta-button {
                        
                    }
                }
            }
            
        }
    }

    @media screen and (max-width: 764px) {
        flex-direction: column;
        
        .header-content,
        .header-content.reversed {
            gap: 30px;
            padding: 7rem 0 2rem;
            
            .header-logo {
                width: 70%;
                display: flex;
                justify-content: center;
            }
            
            .header-title-container {
                width: 100%;
                gap: 30px;
                text-align: center;
                padding-left: 0;
                align-items: center;

                .header-title {
                    padding: 0 5px;
                }

                .header-title,
                .header-tagline {
                    text-align: center;
                }

                .inline-mobile {
                    // display: inline;
                }

                .header-cta-button-container {
                    .header-cta-button {
                        margin: 0 auto;
                        width: 90%;
                        max-width: initial;
                    }
                }

                .notes {
                    max-width: initial;
                }
            }

        }
    }

    @media (max-width: 576px) {
        .header-content,
        .header-content.reversed {
            .header-logo {
                min-width: unset;
                width: 200px;
            }
        }
    }

    @media (max-width: 465px) {
        .header-content,
        .header-content.reversed {
            padding: 1rem 0 2rem;
            gap: 0;
            height: auto;

            .header-title-container {
                .header-title {
                    font-size: 2rem;
                }

                .header-tagline {
                    font-size: 1.4rem;

                    small {
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
}

.header-2 {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;

    .header-content {
        background-color: rgba(255, 255, 255, 0);
        padding: 150px 150px 0;
        
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 30px;

        .header-logo {

            &.first {
                width: 40%;
                position: absolute;
                bottom: 0;
                left: 0;
                min-width: 550px;
            }

            &.second {
                width: 35%;
                position: absolute;
                bottom: 30px;
                left: 28%;
                z-index: 20;
                min-width: 400px;
            }
            
            img {
                width: 100%;
                // max-width: 500px;
            }

        }

        .header-title-container {
            z-index: 30;
            font-family: 'Ubuntu';

            width: 35%;
            max-width: 500px;
            position: relative;

            // background-color: black;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .header-title {
                font-weight: 800;
                font-size: 3.5rem;
                // color: #edc953;
                color: $primaryTextColor;
                font-family: 'Ubuntu';
                text-align: center;

                text-shadow: 3px 3px 2px rgba(95, 157, 247, 1);

                margin: 0 auto;

            }

            .header-cta-container {
                width: 100%;
                max-width: 500px;

                display: grid;
                grid-template-areas: 'search search'
                                    'button icon';

                .header-google-play-search {
                    grid-area: search;

                    img {
                        width: 100%;
                    }
                }

                .header-google-play-button {
                    grid-area: icon;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                    }
                }

                .header-button-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    .header-download-button {
                        grid-area: button;
    
                        width: 100%;
                        height: 50px;
    
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
    
                        text-decoration: none;
                        // color: #12171a;
                        color: $baseColor;
                        font-weight: 700;
                        background: $baseColor3;
                        // background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
    
                        font-size: 16px;
    
                        padding: 0.5rem 1rem;
                        border-radius: 40px;
                        border: 2px solid $baseColor;
        
                        &:hover, &:focus, &:active {
                            // color: #12171a;
                            // background-position: 500px;
                        }
                    }
                }

            }

        }
    }

    @media (max-width: 1280px) {
        .header-content {
            padding: 150px 50px 0;
        }
    }

    @media (max-width: 1150px) {
        .header-content {
            padding: 50px 50px 0;

            .header-logo {
                // opacity: 0.7;

                &.first {
                    min-width: 0;
                    width: 600px;
                    left: -200px;
                }

                &.second {
                    min-width: 0;
                    width: 400px;
                    bottom: 50px;
                    left: 25%;
                }
            }

            .header-title-container {

            }
        }
    }

    @media (max-width: 990px) {
        .header-content {
            padding: 50px 20px 0;
        }
    }

    @media (max-width: 900px) {
        .header-content {
            padding: 50px 0 0;
            justify-content: center;

            .header-logo {
                &.first {
                    width: 80%;
                    left: initial;
                }

                &.second {
                    min-width: 0;
                    width: 400px;
                    bottom: initial;
                    left: initial;
                    right: 0;
                    top: 100px;
                }
            }

            .header-title-container {
                max-width: initial;
                width: 100%;
                height: 100%;

                align-items: center;
                justify-content: center;
                background:rgba(255, 255, 255, 0.5);
            }
        }
    }

    @media (max-width: 599px) {
        .header-content {
            .header-logo {
                &.first {
                    width: 95%;
                }
            }

            .header-title-container {
                padding: 0 5%;

                .header-cta-container {
                    grid-template-areas: 'search'
                                        'icon'
                                        'button';

                    .header-google-play-button {
                        display: none;
                        img {
                            max-width: 300px;
                        }
                    }

                    .header-button-container {
                        .header-download-button {
                            font-size: 18px;
                            height: 70px;
                        }
                    }
                }
            }
        }
    }
}

.header-3 {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    
    width: 100%;
    height: 100%;

    .header-skewed-column {
        padding: 150px 0 0;
        overflow: hidden;
        width: 100%;

        // transform: skewX(-10deg);
            
        .column-wrapper {
            // transform: skewX(10deg);

            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: flex-start;
            position: relative;
            gap: 20px;

            .column-title {
                font-weight: 700;
                font-style: italic;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
                text-align: center;

                font-size: 1.6rem;
    
                .product-title {
                    font-size: 2.3rem;
                    display: block;
                }
            }
    
            .column-button {
                text-decoration: none;
                border-radius: 40px;

                padding: 0.2rem 1rem;
                
                font-size: 1.6rem;
                font-weight: 700;
                font-style: italic;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

                cursor: pointer;

                z-index: 999;
            }
    
            .column-image {
                width: 500px;
                // position: absolute;
                // right: -100px;
                // top: 100%;

                margin-top: 50px;

                img {
                    width: 100%;
                }
            }
    
        }

        &#starter-kit {
            background-color: $baseColor4;

            flex: 1;

            .column-button {
                background-color: $baseColor;
                color: $baseColor4;
            }

            .column-image {
                width: 600px;
            }
        }

        &#legal-boost {
            background-color: $baseColor;
            color: $baseColor3;

            flex: 1;
            
            position: relative;

            .column-button {
                background-color: $baseColor3;
                color: $baseColor;
            }
        }

        &#membership {
            background-color: $primaryTextColor;
            color: $baseColor4;

            flex: 1;

            .column-button {
                background-color: $baseColor4;
                color: $primaryTextColor;
            }
        }
    }

    @media (max-width: 835px) {
        .header-skewed-column {
            .column-wrapper {
                .column-title {
                    font-size: 1.2rem;

                    .product-title {
                        font-size: 1.8rem;
                    }
                }

                .column-button {
                    font-size: 1.2rem;
                }

                .column-image {
                    width: 200px;
                }
            }

            &#starter-kit {
                .column-image {
                    width: 250px;
                }
            }
        }
    }

    @media (max-width: 764px) {
        flex-direction: column;
        padding-top: 100px;
        
        .header-skewed-column {
            // border-top: 1px solid black;
            padding: 20px 0;

            .column-wrapper {
                .column-image {
                    margin-top: 0;
                }
            }
        }
    }

    @media (max-width: 576px) {
        .header-skewed-column {
            .column-wrapper {
                gap: 5px;
            
                .column-image {
                    width: 125px;
                }
            }

            &#starter-kit {
                .column-image {
                    width: 150px;
                }
            }
        }
    }
}

.header-4 {
    // background: $contentBackgroundColor;
    
    width: 100%;
    height: 100%;
    // min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;
    
    // background-image: $headerBackgroundImage;
    background-position: center;
    background-size: cover;

    .header-content {
        background-color: rgba(255, 255, 255, 0);
        padding: 150px 0 0;
        // background: linear-gradient(150deg, $primaryTextColor 10%, $baseColor2 100%);
        // background-color: rgba(#5F9DF7, 1);
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        padding-bottom: 30px;

        .header-title-container {
            font-family: 'Ubuntu';

            // width: 50%;
            position: relative;

            // background-color: black;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .header-title {
                font-weight: 800;
                font-size: 3.5rem;
                // color: #edc953;
                color: $primaryTextColor;
                font-family: 'Ubuntu';
                text-align: center;
                
                .highlighted {
                    color: $baseColor2;
                }
            }

            .header-tagline {
                font-size: 2rem;
                font-weight: 500;
                font-style: italic;
                text-align: center;

                * {
                    display: block;
                }

                small {
                    font-size: 1rem;
                }
            }

        }

        .header-section-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;
            width: 100%;
            padding: 0 10px;

            .header-first-section,
            .header-second-section {
                display: flex;
                flex-direction: row;
                align-items: center;

                .header-section-text-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    .header-section-title {
                        font-family: 'Ubuntu';
                        font-weight: 800;
                        font-size: 1.8rem;
                        text-align: center;
                    }

                    .header-cta-button-container {
                        display: flex;
                        flex-direction: column;
                        gap: 14px;

                        display: flex;
                        // align-items: center;
                        justify-content: flex-start;
                        // gap: 20px;
                        width: 90%;
            
                        .header-cta-button {
                            width: 100%;
                            // max-width: 600px;
                            // margin-top: 20px;

                            padding: 13px;
                            font-size: 15px;
                            text-align: center;
            
                            &:hover, &:focus, &:active {
                                // color: #12171a;
                                // background-position: 500px;
                            }
                        }
                    }
                }

                .header-section-image-container {
                    width: 230px;

                    img {
                        width: 100%;
                    }
                }
            }

            .header-first-section {
                .header-section-text-container {
                    .header-cta-button {
                        background: $baseColor;
                    }
                }
            }

            .header-second-section {
                .header-section-text-container {
                    .header-cta-button {
                        background: $darkBlue;
                    }
                }
            }

            .header-section-delimiter {
                height: 100%;
                width: 5px;
                background-color: $baseColor;
            }
        }

        .notes {
            font-family: 'JosefinSans';
            font-weight: 700;
            text-align: center;

            color: #235910;

            font-size: 16px;

            // max-width: 500px;

            .underline {
                text-decoration: underline;
                text-decoration-color: #dca43c;
            }
        }
    }

    @media ( max-width: 1280px ) {
        .header-content {
            .header-section-container {
                .header-first-section,
                .header-second-section {
                    .header-section-text-container {
                        .header-section-title {
                            font-size: 23px;
                        }
                    }

                    .header-section-image-container {
                        width: 200px;
                    }

                    .header-cta-button-container {
                        .header-cta-button {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    @media ( max-width: 1040px ) {
        .header-content {

            .header-section-container {
                .header-first-section,
                .header-second-section {
                    .header-section-text-container {
                        .header-section-title {
                            font-size: 20px;
                        }
                    }

                    .header-section-image-container {
                        width: 150px;
                    }

                    .header-cta-button-container {
                        .header-cta-button {
                            padding: 12px;
                        }
                    }
                }

                // .header-section-delimiter {
                //     display: none;
                // }
            }
        }
    }
    
    @media ( max-width: 880px ) {
        .header-content {
            .header-section-container {
                .header-first-section {
                    flex-direction: column-reverse;

                    .header-section-text-container {
                        .header-section-title {
                            font-size: 20px;
                        }
                    }

                    // .header-section-image-container {
                    //     width: 150px;
                    // }

                    // .header-cta-button-container {
                    //     .header-cta-button {
                    //         padding: 12px;
                    //     }
                    // }
                }

                .header-second-section {
                    flex-direction: column;

                    .header-section-text-container {
                        .header-section-title {
                            font-size: 20px;
                        }
                    }

                    // .header-section-image-container {
                    //     width: 150px;
                    // }

                    // .header-cta-button-container {
                    //     .header-cta-button {
                    //         padding: 12px;
                    //     }
                    // }
                }

                // .header-section-delimiter {
                //     display: none;
                // }
            }
        }
    }

    @media ( max-width: 764px ) {
        .header-content {
            padding: 0;
        }
    }

    @media ( max-width: 660px ) {
        .header-content {
            .header-section-container {
                flex-direction: column;
                align-items: center;
                width: 100%;

                .header-first-section {
                    flex-direction: row-reverse;

                    .header-section-text-container {
                        .header-section-title {
                            font-size: 20px;
                        }
                    }

                    // .header-section-image-container {
                    //     width: 150px;
                    // }

                    // .header-cta-button-container {
                    //     .header-cta-button {
                    //         padding: 12px;
                    //     }
                    // }
                }

                .header-second-section {
                    flex-direction: row;

                    .header-section-text-container {
                        .header-section-title {
                            font-size: 20px;
                        }
                    }

                    // .header-section-image-container {
                    //     width: 150px;
                    // }

                    // .header-cta-button-container {
                    //     .header-cta-button {
                    //         padding: 12px;
                    //     }
                    // }
                }

                .header-section-delimiter {
                    // display: none;
                    height: 5px;
                    width: 80%;
                }
            }

            .notes {
                width: 90%;
            }
        }
    }

    @media ( max-width: 576px ) {
        .header-content {
            .header-title-container {
                .header-title {
                    font-size: 2rem;
                }

                .header-tagline {
                    font-size: 1.5rem;
                }
            }
        }
    }
}