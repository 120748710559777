$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

.summary-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    min-height: 70vh;

    position: relative;

    background: linear-gradient(40deg, $baseColor2 50%, $baseColor1 100%);

    padding: 150px 0 2rem;

    .summary-page-title {
        color: $baseColor4;
        border-bottom: 5px solid $baseColor3;
        font-size: 3rem;
        font-weight: 700;

        position: relative;

        text-align: center;

        margin: 0 2rem 1.5rem;

        @media (max-width: 600px) {
            border-bottom: 0;
        }
    }

    .summary-page-body {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        
        gap: 2rem;

        padding: 0 2rem;
        width: 100%;

        .summary-page-body-desc {
            background-color: $baseColor4;
            border: 3px solid $baseColor1;
            border-radius: 20px;

            width: 60%;

            padding-right: 2rem;

            display: flex;
            align-items: center;
            justify-content: space-around;

            .summary-page-body-desc-image {
                width: 200px;
                
                img {
                    width: 100%;
                }
            }

            .summary-page-body-desc-container {
                display: flex;
                flex-direction: column;
                gap: 0.3rem;
                padding: 1rem 0;

                .summary-page-body-desc-title {
                    h3 {
                        margin: 0;
                        font-weight: 700;
                    }
                }
    
                .summary-page-body-desc-desc {
                    font-size: 18px;
                    font-weight: 600;
                }
            }

        }

        .summary-page-body-detail {
            width: 70%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            min-height: 200px;
            gap: 1rem;

            .summary-page-body-detail-choice {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;

                .summary-page-body-detail-choice-item {
                    min-width: 100px;
                    padding: 0.3rem 0.6rem;

                    outline: 1px solid rgba(0, 0, 0, 0);
                    border: 2px solid $baseColor1;

                    background-color: $baseColor4;
                    border-radius: 10px;
                    font-weight: 500;
                    
                    &.active {
                        outline-color: $baseColor1;
                        font-weight: 600;
                        color: $baseColor1;
                    }
                }
            }

            .summary-page-body-detail-phone-no {
                width: 100%;
                background-color: $baseColor4;
                border: 3px solid $baseColor1;
                border-radius: 20px;

                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5rem 1rem;
                
                .phone-no-label {
                    display: inline-block;
                    min-width: 200px;
                    width: 30%;

                    font-size: 20px;
                    font-weight: 600;
                }

                .phone-no-input {
                    display: inline-block;
                    width: 70%;

                    input.form-control {
                        border: none;
                        font-size: 20px;
                        margin-bottom: 0;

                        text-align: right;
                        font-weight: 600;
                    }
                }

                .phone-no-arrow {
                    cursor: pointer;

                    * {
                        vertical-align: middle;
                        font-size: 20px;
                    }
                }
            }

            .summary-page-body-detail-price {
                width: 100%;
                border-radius: 20px;
                padding: 1rem 1rem;

                background-color: $baseColor4;
                border: 3px solid $baseColor1;

                .price-item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    font-size: 20px;

                    &.total {
                        .price-item-label {
                            font-weight: 600;
                        }

                        .price-item-price {
                            font-weight: 700;
                        }
                    }

                    .price-item-price {
                        font-weight: 600;
                    }
                }

                .summary-btn-container {
                    margin-top: 25px;

                    .btn-checkout {
                        float: right;

                        background: linear-gradient(120deg, $baseColor1 20%, $baseColor2 100%);
                        color: $baseColor4;
                        padding: 0.5rem 0.6rem;
                        min-width: 150px;
                        font-size: 17px;
                        border: none;
                        border-radius: 20px;

                        &.disabled {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        
    }
}

.input-pin-modal {
    font-family: 'JosefinSans';

    .modal-content {
        & > * {
            background-color: $baseColor4;
        }

        .modal-header {
            .modal-title {
                font-weight: 700;
            }
        }

        .modal-body {
            label {
                font-size: 18px;
                font-weight: 600;
            }

            .form-control {
                font-size: 18px;
            }
        }

        .modal-footer {
            .btn-primary {
                background-color: $baseColor1;
                border-color: $baseColor1;
            }
        }
    }
}

.result-toast {
    top: 20px;
    right: 20px;
    position: fixed;
    z-index: 9999;
}

@media (max-width: 768px) {
    .summary-page {
        .summary-page-body {
            flex-direction: column;
            align-items: center;
        
            .summary-page-body-desc {
                width: 100%;
        
                justify-content: flex-start;
            }
        
            .summary-page-body-detail {
                width: 100%;

                align-items: flex-start;
            }
        }
    }
}

@media (max-width: 440px) {
    .summary-page {
        .summary-page-title {
            font-size: 2.5rem;
        }

        .summary-page-body {
            .summary-page-body-desc {
                .summary-page-body-desc-container {
                    .summary-page-body-desc-title {
                        h3 {
                            font-size: 1.2rem;
                        }
                    }

                    .summary-page-body-desc-desc {
                        font-size: 14px;
                    }
                }
            }

            .summary-page-body-detail {
                .summary-page-body-detail-choice {
                    .summary-page-body-detail-choice-item {
                        font-size: 14px;
                    }
                }

                .summary-page-body-detail-phone-no {
                    flex-wrap: wrap;
                    padding: 0.3rem 1rem;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .phone-no-label {
                        font-size: 16px;
                        min-width: 0;
                        width: 100%;
                    }
    
                    .phone-no-input {
                        width: 100%;

                        input.form-control {
                            font-size: 16px;
                            text-align: left;
                            border-bottom: 1px solid $baseColor2;
                            padding: 0;
                            margin-bottom: 15px;
                        }
                    }
    
                    .phone-no-arrow {
                        display: none;
                        // width: 10%;

                        * {
                            font-size: 16px;
                        }
                    }
                }

                .summary-page-body-detail-price {
                    padding: 0.7rem 1rem;
    
                    .price-item {
                        font-size: 18px;
                    }
    
                    .summary-btn-container {
                        .btn-checkout {
                            padding: 0.2rem 0.4rem;
                        }
                    }
                }
            }
        }
    }
}