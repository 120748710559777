$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$defaultBgColor: var(--white-color);
$darkTextColor: var(--primary-text-color);
$whiteTextColor: var(--secondary-text-color);

.business-entity-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    gap: 3rem;
    width: 100%;
    max-width: 1200px;

    padding: 0 2rem;

    .business-entity-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        width: 50%;

        .business-entity-item {
            background-color: $defaultBgColor;

            width: 100%;
            border: 2px solid $baseColor1;
            border-radius: 20px;
            padding: 16px 15px;

            display: flex;
            flex-direction: column;
            gap: 1rem;

            
            .base-price {
                display: flex;
                justify-content: space-between;
                
                font-size: 30px;

                font-weight: 600;

                @media (max-width: 576px) {
                    // flex-direction: column;
                    // align-items: flex-start;
                    // justify-content: flex-start;
                    font-size: 20px;
                }
            }

            .additional-price {
                display: flex;
                align-items: center;
                gap: 1rem;
                
                font-size: 17px;
                
                .price-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    width: 100%;

                    .price-label {
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        
                        width: 50%;
                    }

                    .price-number {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
    
                        font-weight: 700;

                        &.real {
                            margin-right: 0.5rem;
                            text-decoration: line-through;
                            // text-decoration-color: $baseColor1;
                        }
                    }

                }

                @media (max-width: 576px) {
                    // flex-direction: column;
                    // align-items: flex-start;
                    // justify-content: flex-start;
                    font-size: 12px;
                }

                .order-btn-container {
                    
                    .btn-order {
                        text-decoration: none;
                        color: $whiteTextColor;
                        background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
    
                        padding: 0.3rem 1rem;
                        border-radius: 20px;

                        cursor: pointer;

                        transition: all 0.5s ease;

                        &:hover, &:active, &:focus {
                            background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                            color: $darkTextColor;
                        }
                    }
                }

            }
        }
    }

    .business-entity-extra {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .business-entity-add-ons {
            background-color: $defaultBgColor;
            // background-color: $baseColor4;
            padding: 16px 1.5rem;
            border: 2px solid $baseColor1;
            border-radius: 20px;

            .add-ons-title {
                h2 {
                    font-size: 1.8rem;
                    font-weight: 600;
                }
            }

            .add-ons-list {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .add-ons-items {
                    font-size: 20px;

                    .add-ons-price {
                        font-weight: 700;
                    }
                }
            }
        }

        .business-entity-vo {
            background-color: $defaultBgColor;
            // background-color: $baseColor4;
            padding: 16px 1.5rem;
            border: 2px solid $baseColor1;
            border-radius: 20px;

            .vo-title {
                h2 {
                    font-size: 1.8rem;
                    font-weight: 600;
                }
            }

            .vo-list {
                .vo-items {
                    font-size: 20px;
                }
            }
        }
    }

    
    @media screen and (max-width: 963px) {
        flex-direction: column;
        
        .business-entity-list {
            width: 100%;
        }

        .business-entity-extra {
            width: 100%;

            flex-direction: row;
            // flex-wrap: wrap;

            & > * {
                width: 50%;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .business-entity-extra {
            flex-wrap: wrap;

            & > * {
                width: 100%;
            }
        }
    }

}