$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$darkTextColor: var(--primary-text-color);
$contentBackgroundColor: var(--white-color);

$textColor: $baseColor4;
$titleLineColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);

.legal-service {
    width: 100%;
    // background-color: rgb(246, 246, 246);
    background-color: $baseColor1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    // gap: 1rem;

    padding: 120px 0 2.5rem;

    .legal-service-title, .legal-service-item-title {
        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .legal-service-title {
        font-size: 3rem;
        border-bottom: 5px solid $titleLineColor;
        color: $baseColor4;
        padding-bottom: 0.6rem;
        text-align: center;
    }

    @media screen and (max-width: 670px) {
        .legal-service-title {
            padding: 0 1rem;
            // border: 0;
            width: fit-content;
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }

        // padding: 2.5rem 0 1rem;
        padding: 120px 0 1rem;
    }

    @media screen and (max-width: 600px) {
        .legal-service-title {
            border: none;
            font-size: 2rem;
        }
    }

    .legal-service-subtitle {
        margin-bottom: 1rem;
        padding-bottom: 0.6rem;
        font-weight: 700;
    }

    .legal-service-loading-section {
        width: 100%;
        padding: 0.8rem 0rem 2.5rem;
        margin: 0.6rem 0 2rem;

        position: relative;

        .spinner-grow, .spinner-loading {
            background-color: $baseColor4;
        }
    }

    .legal-service-item-container {
        width: 100%;
        padding: 0.8rem 0rem 2.5rem;
        margin: 0.6rem 0 2rem;

        .legal-service-item-dot-list {
            
            .react-multi-carousel-dot--active button{
                background-color: $baseColor3;
            }

            li button {
                background-color: $baseColor4;
            }
        }
    
        .legal-service-item-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            // gap: 20px;
    
            .legal-service-item {
                // background-color: $baseColor4;            
                background-color: $contentBackgroundColor;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: first baseline;
    
                position: relative;
    
                min-width: 350px;
                width: 60%;
                height: 500px;
                
                border-radius: 30px;
                
                padding: 0 1rem 1.5rem;
    
                .legal-service-item-title {
                    margin-bottom: 1rem;
                    text-align: center;
                    color: $baseColor1;
                }
    
                .legal-service-item-desc {
                    text-align: center;
                    padding: 0 0.6rem;
                    // color: $baseColor2;
                    color: $darkTextColor;
                }
    
                .legal-service-item-icon {
                    width: 235px;
                    // margin-bottom: 0.8rem;
                }
    
                .legal-service-item-price {
                    font-weight: 700;
                    color: $baseColor1;    
                }
    
                .btn-legal-service {
                    position: absolute;
                    bottom: 30px;
    
                    // margin-top: 40px;
                    padding: 10px 20px;
                    font-weight: 500;
    
                    text-decoration: none;
                    
                    background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                    -webkit-background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                    -moz-background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                    color: $textColor;
    
                    border-radius: 20px;
    
                    transition: all 0.5s ease-in-out;
    
                    &:hover, &:focus, &:active {
                        background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                        -webkit-background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                        -moz-background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                        color: $primaryTextColor;
                    }
                }
            }

            @media screen and (max-width: 860px) {
                .legal-service-item {
                    min-width: 0;
                    max-width: 100%;
                    width: 80%;
                }
            }
        }
    }

    .legal-service-chart-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 1rem;
        
        width: 100%;
        
        .legal-service-chart {
            // width: 60%;
            
            color: $baseColor4;
            // border: 3px solid white;

            padding: 4rem 2rem;

            .legal-service-chart-table {
                width: 100%;

                .new-product {
                    font-weight: 700;

                    .new-product-icon {
                        width: 70px;
                    }
                }

                td {
                    padding: 5px;
                    font-size: 21px;
                    vertical-align: baseline;

                    img {
                        width: 50px;
                    }

                    &.service-name {
                        font-weight: 700;
                    }
                }

                @media (max-width: 768px) {
                    td {
                        font-size: 18px;
                    }
                }

                @media (max-width: 576px) {
                    .new-product {
                        .new-product-icon {
                            width: 50px;
                        }
                    }

                    td {
                        font-size: 16px;

                        img {
                            width: 30px;
                        }
                    }
                }
            }
        }

        .legal-service-download-container {
            // border: 3px solid white;
            // width: 40%;

            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            gap: 1.5rem;
            padding-right: 20px;

            .legal-service-mascot {
                width: 270px;

                img {
                    width: 100%;
                }
            }

            .legal-service-caption {
                color: $baseColor4;
            }

            .legal-service-btn-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;

                .btn-download {
                    text-decoration: none;
                    min-width: 200px;
                    text-align: center;
                    padding: 0.5rem 1rem;

                    border-radius: 25px;

                    font-size: 22px;
                    
                    &.btn-download-ios {
                        background-color: $baseColor3;
                        color: $baseColor1;    
                    }
                    
                    &.btn-download-android {
                        background-color: $baseColor2;
                        color: $baseColor4;
                    }

                    img {
                        width: 30px;
                    }
                }
            }

            @media (max-width: 576px) {
                .legal-service-mascot {
                    width: 200px;
                }

                .legal-service-btn-container {
                    flex-direction: column;
                }
            }
        }

        @media (max-width: 1100px) {
            flex-direction: column;
            gap: 0;

            .legal-service-download-container {
                margin-bottom: 2rem;
                padding-right: 0;
            }
        }
    }

    .legal-service-notes {
        align-self: flex-start;
        margin-left: 2.5rem;

        &-item {
            display: block;
            font-size: 13px;
            color: $baseColor4;
        }

        @media screen and (max-width: 568px) {
            margin-left: 0.5rem;
            padding: 0 1rem;
        }
    }
}