$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$navBorderColor: #0F2859;
$textColor: $baseColor4;

.navbar {
    height: 100px;
    // height: 13vh;
    padding: 10px 20px;
    position: fixed;
    z-index: 1002;
    top: 0;
    width: 100%;

    &.custom-navbar-bg {
        background: $baseColor1;
        background: linear-gradient(60deg, $baseColor2 10%, $baseColor1 100%);
    }

    .navbar-brand {
        font-family: 'Ubuntu';
        font-weight:  700;
        color: $baseColor3;

        .nav-logo {
            width: 160px;
            // padding: 10px 0;
        }
    }

    .navbar-toggler {
        // border: 1px solid $baseColor4;
        // background-color: $baseColor3;
    }

    .nav-link {
        font-weight: 700;
        font-size: 19px;
        color: $baseColor4;
        margin-left: 20px;
        border-bottom: 3px solid rgba(0, 0, 0, 0);

        padding-bottom: 0;

        background-color: rgba(0,0,0,0);
        border: none;
        outline: none;

        &:hover, &:focus, &:active {
            border-color: $baseColor3;
        }

        &#services {
            &:hover, &:focus, &:active {
                .navbar-hover {
                    display: block;
                }
            }

        }
    }

    .navbar-hover {
        width: 320px;
        // width: fit-content;
        // max-width: 1000px;
        min-height: 200px;

        z-index: 1010;
        position: absolute;
        top: 60px;
        left: 105px;
        padding: 1rem 1rem;
        display: none;

        &.show {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;

            overflow-y: auto;
            
            column-gap: 0.8rem;
            row-gap: 2rem;
        }

        background-color: $baseColor2;

        .dropdown-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            flex-direction: column;

            // width: 100%;
            min-width: 150px;
            
            .dropdown-name {
                width: 100%;
                // text-align: center;

                font-size: 17px;
                font-weight: 700;
                text-transform: uppercase;

                margin-bottom: 20px;

                color: $baseColor4;
            }

            .dropdown-items {
                width: 100%;

                display: grid;
                grid-template-columns: 200px;
                column-gap: 50px;
                row-gap: 10px;
                
                .dropdown-item {
                    text-decoration: none;
                    color: $textColor;

                    font-size: 12px;
                    font-weight: 700;

                    width: fit-content;

                    &.highlighted {
                        color: $baseColor3;
                    }

                    &:active, 
                    &:hover, 
                    &:focus,
                    &.active {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .navbar-collapse {
        .navbar-toggle {
            position: relative;
        }

        .navbar-nav {

            .nav-link {
                &:hover, &:focus, &:active {
                    
                }
            }
        }
    }

    @media (max-width: 1155px) {
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    margin-left: 0;
                }
            } 
        } 
    }

    @media screen and (max-width: 1199px) {
        .navbar-collapse {
            position: absolute;
            top: 99px;
            right: 0;

            .navbar-nav {
                background: linear-gradient(150deg, $baseColor2 10%, $baseColor1 100%);
                width: 100vw;
                // float: right;
                // position: absolute;
                // right: 20px;
                // z-index: 1003;
        
                .nav-link {
                    padding: 18px 50px;
                    margin: 0;
                    border-bottom: 1px solid rgba($navBorderColor, 0.1);
                    font-size: 19px;
                    border: none;
        
                    &:hover, &:active, &:focus {
                        background-color: $navBorderColor;
                    }
                }
            }
        }
    }


}