$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$darkTextColor: var(--primary-text-color);
$contentBackgroundColor: var(--white-color);
$successColor: var(--highlight-color);

$textColor: $baseColor4;
$titleLineColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);

.membership {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3em;

    padding: 120px 0 2.5rem;
    // padding: 60px 0 2.5rem;

    background-color: $baseColor1;
    color: $baseColor4;

    .membership-title {
        font-weight: 700;
        font-family: 'Ubuntu';

        font-size: 3rem;
        margin-bottom: 0;
        border-bottom: 5px solid $titleLineColor;
        
        padding-bottom: 0.6rem;
        text-align: center;
    }

    .membership-content {
        width: 100%;
        display: flex;
        // align-items: center;
        justify-content: center;
        gap: 3rem;
        
        .membership-table {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            table {
                border-color: $baseColor2;
    
                th, td {
                    padding: 1rem;
                }
    
                thead {
                    background-color: $baseColor3;
                    // color: $baseColor4;
                }
    
                tbody {
                    tr {
                        td {
                            background-color: $baseColor4;

                            &.service-name {
                                background-color: $baseColor3;
                                font-weight: 600;
                            }
                        }
                    }
                }
    
                tfoot {
                    background-color: $baseColor3;
                    // color: $baseColor4;
                }
            }

            .membership-table-small-notes {
                max-width: 600px;
            }
        }

        .membership-description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 15px;

            width: 40%;

            .description-mascot {
                width: 400px;
                margin-bottom: 15px;

                img {
                    width: 100%;
                }
            }

            .description-text {
                font-size: 27px;
                max-width: 400px;
                text-align: center;
            }

            .description-btn-container {
                margin-top: 2rem;
                font-weight: 600;
    
                .btn-membership {
                    text-decoration: none;

                    background-color: $baseColor2;
                    color: $baseColor4;

                    padding: 1rem 2rem;
                    border-radius: 40px;

                    font-size: 23px;

                    border: none;

                    cursor: pointer;
                    word-break: keep-all;
                    text-wrap: nowrap;
                }
            }
        }

        @media (max-width: 1200px) {
            flex-direction: column-reverse;
            align-items: center;

            .membership-table {
                width: 90%;
            }

            .membership-description {
                width: 90%;
            }
        }

        @media (max-width: 576px) {
            .membership-table {
                table {
                    tbody {
                        td {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .membership-title {
            border-bottom: none;
        }
    }
}