$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);
$titleLineColor: $baseColor3;

.b2b-package-enterprise {
    width: 100%;
    background-color: $baseColor;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    // gap: 20px;
    column-gap: 20px;

    padding: 120px 0;
    // padding: 60px 0 2.5rem;

    .enterprise-title {
        font-size: 3rem;
        margin-bottom: 0.7rem;
        border-bottom: 5px solid $titleLineColor;
        color: $baseColor4;
        padding-bottom: 0.6rem;
        text-align: center;

        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .enterprise-content {
        display: flex;
        padding: 0.5rem 2rem;
        color: $baseColor4;

        font-weight: 600;

        .first-column {
            width: 37%;
            
            .legal-service-list-title {
                margin-bottom: 10px;
                font-size: 24px;
                font-weight: 700;

                position: relative;
            }
            
            .legal-service-list {
                list-style: none;
                font-size: 18px;
                padding-left: 0;

                .legal-service-item {
                    display: flex;
                    align-items: center;

                    img {
                        width: 50px;
                        margin-right: 15px;
                    }

                    margin-bottom: 10px;
                }
            }
        }
     
        .second-column {
            width: 63%;
            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            
            .enterprise-mascot {
                width: 60%;

                img {
                    width: 100%;
                }
            }
            
            .enterprise-cta {
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
    
                .btn-order-enterprise {
                    height: fit-content;
    
                    &:hover {
                        background: $baseColor;
                        color: inherit;
                    }
                }
            }
        }

        .third-column {
            width: 35%;
            display: flex;
            flex-direction: column;

            .benefit-section {
                .benefit-title {
                    margin-bottom: 10px;
                    font-size: 24px;
                    font-weight: 700;
                }
                
                .benefit-list {
                    font-size: 18px;
                    padding-left: 15px;
        
                    li {
                        img {
                            width: 50px;
                            margin-right: 15px;
                        }
        
                        margin-bottom: 10px;
                    }
                }

            }

            .enterprise-description {
                font-size: 23px;
                margin-top: 3rem;
            }
        }

        // Tooltip
        .exclude-include {
            position: absolute;
            font-size: 14px;
            background-color: $baseColor;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            border: 1px solid $baseColor3;
            border-radius: 20px;
            padding: 20px;
            
            left: 320px;
            right: -200px;
            width: fit-content;
            height: fit-content;

            display: flex;
            flex-direction: column;
            // align-items: center;
            // justify-content: center;
            gap: 20px;

            z-index: 990;

            .excluding-items,
            .including-items {
                ol {
                    margin: 0;
                }
                // margin-bottom: 20px;
            }
        }

        #document-settings {
            position: relative;
        }

        .document-settings {
            position: absolute;
            font-size: 14px;
            background-color: $baseColor;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            border: 1px solid $baseColor3;
            border-radius: 20px;
            padding: 20px;
            
            top: -170px;
            left: 320px;
            right: -250px;
            width: fit-content;
            height: fit-content;

            z-index: 990;

            .excluding-items,
            .including-items {
                ol {
                    margin: 0;
                }
                // margin-bottom: 20px;
            }
        }

        @media (max-width: 1200px) {
            .first-column {
                width: 50%;
            }

            .second-column {
                width: 50%;

                .enterprise-mascot {
                    width: 70%;
                }
                
                .enterprise-cta {
                    // width: 30%;
    
                    .btn-order-enterprise {
                        font-size: 16px;
                    }
                }
            }


        }

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .exclude-include {
                width: 80%;
                left: 0;
            }

            .document-settings {
                left: 0;
            }

            .second-column {
                .enterprise-description {
                    width: 100%;
                }

                .enterprise-cta {
                    width: 100%;
                }
            }

            .third-column {
                width: 100%;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-around;
                margin-top: 2rem;

                .benefit-section {
                    width: 45%;
                }

                .enterprise-description {
                    width: 45%;
                    margin-top: 0;
                }
            }
        }

        @media (max-width: 599px) {

            .first-column {
                width: 100%;
            }

            .second-column {
                flex-direction: row-reverse;
                width: 100%;
            }

            .third-column {
                flex-direction: column;

                .benefit-section {
                    width: 100%;
                }

                .enterprise-description {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .enterprise-title {
            border-bottom: none;
        }

        .enterprise-content {
            .second-column {
                flex-direction: column;

                .enterprise-mascot {
                    width: 100%;
                }
            }

            &:nth-of-type(2) {
                flex-direction: column-reverse;
            }
        }
    }

    @media (max-width: 576px) {
        padding: 60px 1rem 2.5rem;

        .enterprise-title {
            font-size: 2rem;
            margin-bottom: 0;
        }

        .enterprise-content {
            
        }
    }

    .hover-icon {
        margin-left: 5px;
        cursor: pointer;

        border: none;
        outline: none;
        background-color: rgba(0,0,0,0);
        color: inherit;
    }
}