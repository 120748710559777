* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: var(--primary-text-color); */
}

.App {
  font-family: 'JosefinSans';
  background-color: var(--white-color);
  color: var(--primary-text-color);
  position: relative;
  min-height: 100vh;
  height: fit-content;
}

.App.dark-mode {
  background-color: #222222;
  color: #FFF;
}

.App .hide {
  visibility: hidden;
  opacity: 0;
}

.base-bg-dark {
  background-color: var(--dark-color);
}

/* Fonts */

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/JosefinSans-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/JosefinSans-Italic-VariableFont_wght.ttf');
  font-style: italic;
}

/* Static 'JosefinSans' Font */

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./Fonts/JosefinSans/static/JosefinSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

/* Static 'Ubuntu' Font */
@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./Fonts/Ubuntu/Ubuntu-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}