$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);
$titleLineColor: $baseColor3;

.b2b-package-legal-boost {
    width: 100%;
    background-color: $baseColor2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;

    padding: 120px 1rem 2.5rem;
    // padding: 60px 1rem 2.5rem;

    .legal-boost-title {
        font-size: 3rem;
        margin-bottom: 2rem;
        border-bottom: 5px solid $titleLineColor;
        color: $baseColor4;
        padding-bottom: 0.6rem;
        text-align: center;

        font-weight: 700;
        font-family: 'Ubuntu';
    }

    .legal-boost-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        
        width: 100%;

        .legal-boost-description {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;

            .description-mascot {
                width: 400px;

                img {
                    width: 100%;
                }
            }

            .description-text {
                font-size: 24px;
                text-align: center;
                color: $baseColor4;
                // width: 80%;
            }
        }

        .legal-boost-pricing {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 60%;

            .pricing-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                gap: 10px;

                background-color: #FFF;
                border: 3px solid $baseColor3;

                border-radius: 50px;
                width: 90%;
                padding: 0 2rem 1rem;

                .pricing-card-rocket {

                }

                .pricing-card-mascot {
                    width: 300px;

                    img {
                        width:  100%;
                    }
                }

                .pricing-card-title {
                    font-weight: 600;
                    font-size: 2.7em;
                }

                .pricing-card-price {
                    font-size: 1.5rem;
                    font-weight: 700;

                    border-bottom: 3px solid $baseColor3;
                    line-height: 1.2rem;
                    margin: 1rem 0;

                    color: $baseColor;
                }

                .pricing-card-cta-button-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .cta-button {
                        max-width: 300px;
                    }
                }

                .pricing-card-expiry-time {
                    margin-bottom: 20px;
                }

                .pricing-card-description {
                    .description-item-list {
                        list-style: lower-latin;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .legal-boost-content {
            flex-direction: column;

            .legal-boost-description {
                .description-text {
                    text-align: center;
                }
            }

            .legal-boost-pricing {
                width: 50%;
            }
        }
    }

    @media (max-width: 990px) {

        .legal-boost-content {
            .legal-boost-description {
                .description-mascot {
                    width: 300px;
                }
            }

            .legal-boost-pricing {
                width: 70%;
            }
        }
    }

    @media (max-width: 768px) {
        .legal-boost-title {
            border-bottom: none;
        }

        .legal-boost-content {
            .legal-boost-description {
                .description-text {
                    font-size: 28px;
                }
            }

            .legal-boost-pricing {
                width: 90%;

                .pricing-card {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 576px) {
        padding: 60px 1rem 2.5rem;

        .legal-boost-title {
            font-size: 2rem;
            margin-bottom: 0;
        }

        .legal-boost-content {
            .legal-boost-description {
                gap: 20px;

                .description-mascot {
                    width: 200px;
                }

                .description-text {
                    font-size: 1.5rem;
                }
            }

            .legal-boost-pricing {
                width: 100%;

                .pricing-card {
                    padding: 0 1rem 1rem;
                }
            }
        }
    }
}