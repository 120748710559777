$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);

$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$footerTextColor: var(--primary-text-color);

$primaryTextColor: var(--primary-text-color);
$secondaryTextColor: var(--secondary-text-color);

$outlineColor: #FFF;
$outlineBlur: 0;

.footer-contact {
    width: 100%;
    background: $baseColor2;
    background: linear-gradient(10deg, $baseColor 10%, $baseColor2 60%);
    color: $footerTextColor;

    // height: fit-content;
    
    // padding: 3rem 0 0;
    
    /* Across browsers req */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &-title, &-content {
        
    }

    &-title {
        font-family: 'Ubuntu';
        font-size: 5rem;
        // color: $primaryTextColor;
        color: $baseColor4;
        font-weight: 900;
        margin: 0 7rem 2rem;
        border-bottom: 5px solid $baseColor3;
        width: max-content;

        padding-top: 3rem;

        max-width: 1200px;

        @media screen and (max-width: 517px) {
            border: none;
        }
    }
    
    &-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 3rem;

        margin: 0 7rem 0 7rem;

        .content-first-column,
        .content-second-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            
            .contact-image-container {
                width: 100%;
                
                iframe {
                    width: 100%;
                }
            }
        
            .contact-info-container {
                h3, h4 {
                    color: $baseColor3;
                }

                // width: 65%;
                .contact-info-content {
                    color: $baseColor4;

                    a {
                        transition: all 0.5s ease;
                        margin-bottom: 0.5rem;

                        &:hover, a:active, a:focus {
                            color: $baseColor3;
                        }
                    }
                }
            }

            .footer-contact-socmed {
                align-self: flex-start;

                .contact-info-content {
                    flex-wrap: wrap;
                }

                &.contact-info-item {
                    h3 {
                        color: $baseColor3;
                    }

                    .contact-info-content {
                        display: flex;
                        gap: 1.5rem;
                        font-size: 35px;
                        color: $baseColor4;

                        a {
                            transition: all 0.5s ease;

                            &:hover, a:active, a:focus {
                                color: $baseColor3;
                            }
                        }
                    }
                }   
            }

        }

        .footer-form {
            height: 100%;
            color: $baseColor4;

            input, textarea {
                color: $baseColor4;
            }

            .btn-contact-form {
                width: 100%;
                color: #12171a;
                font-weight: 600;

                background: $baseColor3;
                background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);

                text-decoration: none;
                font-size: 18px;
                padding: 15px 25px;
                min-width: 200px;
                border: none;
                border-radius: 30px;

                &:disabled {
                    background: none;
                    background-color: #222222;
                    color: #FFF;
                    opacity: 0.6;
                }
            }
        }

    }

    .contact-info-item {
        color: $footerTextColor;
        // margin-bottom: 10vh;

        h3 {
            font-weight: 700;
            font-size: 25px;
        }
        
        .contact-info-content {
            width: 100%;
            font-weight: 500;
            font-size: 20px;
            
            & > * {
                display: block;
                white-space: pre-line;
            }

            & > a {
                color: inherit;
                font-weight: inherit;
                text-decoration: none;
            }
        }

        
        &.center {
            display: flex;
            flex-direction: column;
            align-items: center;

            text-align: center;

            .contact-info-content {
                * {
                    display: inline;
                }
            }
        }
    }
}

.copyright {
    width: 100%;
    background: $baseColor3;
    background: linear-gradient(150deg, $baseColor4 2%, $baseColor3 60%);
    color: $baseColor;
    padding: 0.8rem 1rem;
    margin-top: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 990px) {
    .footer-contact {
        &-title {
            font-size: 4rem;
        }

        &-content {
            flex-direction: column;

            .contact-image-container,
            .contact-info-container {
                width: 100%;
            }
        }

        &-socmed {
            margin-top: 0;
            margin-left: 1rem;
        }

    }
}

@media (max-width: 768px) {
    .footer-contact {
        // padding-bottom: 7rem;

        &-title {
            margin: 0 2rem 2rem;
        }

        &-content {
            margin: 0 2rem 2rem;
            
            .content-first-column,
            .content-second-column {
                .contact-info-container {
                    .contact-info-content {
                        font-size: 1rem;
                    }
                }
            }
        }

        .copyright {
            margin-top: 5rem;
            // padding-bottom: 7rem;
        }
    }
}

@media (max-width: 450px) {
    .footer-contact {
        // padding: 1rem 0 7rem;
        padding: 1rem 0 0;

        &-title {
            text-align: center;
            width: 100%;
            font-size: 3rem;
            border: none;
            margin-left: 0;
        }

        &-content {

            .content-first-column,
            .content-second-column {
                width: 100%;
            }

            .contact-image-container {
                iframe {
                    width: 100%;
                }
            }

            .contact-info-container {
                h3 {
                    color: $baseColor3;
                }

                // width: 65%;
                .contact-info-content {
                    color: $baseColor4;
                    font-size: 5.5vw;
                    width: 100%;

                    a {

                        &:hover, a:active, a:focus {
                            color: $baseColor3;
                        }
                    }
                }
            }
        }

        &-socmed {
            margin-left: 0;
        }
    }
}