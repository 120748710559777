$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$backgroundColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);

.business-entity-footer {
    background-color: $backgroundColor;
    color: $baseColor1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    min-height: 400px;
    width: 100%;
    padding: 2rem 0;

    .business-entity-footer-assets {
        width: 30%;
        max-width: 350px;

        img {
            width: 100%;
        }
    }

    .business-entity-footer-header {
        
        h2 {
            font-size: 3rem;
            font-weight: 700;
            width: 80%;
            margin: 0 auto;
            text-align: center;
        }
    }

    .business-entity-footer-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        .btn-download {
            text-decoration: none;
            min-width: 200px;
            text-align: center;
            padding: 0.5rem 1rem;

            border-radius: 25px;

            font-size: 22px;
            
            &.btn-download-ios {
                background-color: $baseColor3;
                color: $baseColor1;    
            }
            
            &.btn-download-android {
                background-color: $baseColor2;
                color: $baseColor4;
            }

            img {
                width: 30px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .business-entity-footer-header {
            h2 {
                font-size: 2.7rem;
            }
        }

        .business-entity-footer-button-container {
            width: 100%;
            flex-direction: column;

            .btn-download {
                font-size: 20px;
                border-radius: 40px;

                width: 80%;
                max-width: 400px;

            }
        }
    }

    @media screen and (max-width: 480px) {
        .business-entity-footer-header {
            h2 {
                font-size: 2.2rem;
            }
        }
    }
}