$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$backgroundColor: var(--white-color);

.article-container {
    padding: 120px 2rem 3rem;
    background-color: $baseColor2;

    .article-container-header {
        display: flex;
        align-items: center;
        justify-content: center;

        .article-container-title {
            font-weight: 700;
            font-family: 'Ubuntu';
            font-size: 3.5rem;
    
            margin-bottom: 3rem;
            border-bottom: 5px solid $baseColor3;
            padding-bottom: 0.6rem;
            
            color: $baseColor4;
    
            text-align: center;
        }
    }
}