$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$primaryTextColor: var(--primary-text-color);

.btn-consult {
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    text-decoration: none;
    // color: #12171a;
    color: $baseColor4;
    font-weight: 700;
    background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
    // background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);

    font-size: 18px;

    padding: 1rem 2rem;
    border-radius: 40px;

    .btn-consult-icon {
        font-size: 25px;
        width: 30px;
    }

    transition: all 0.5s ease-in-out;
    
    &:hover, &:focus, &:active {
        // background-position: 275px;
        background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
        color: $primaryTextColor;
    }

    &.is-fixed {
        position: fixed;
        z-index: 1001;
        bottom: 30px;
        right: 30px;

        align-self: center;

        @media screen and (max-width: 768px) {
            text-align: center;
            right: initial;
            bottom: 4rem;
            // font-size: 15px;
            // border-radius: 50%;
            padding: 0.7rem;
            width: 95%;
            margin: 0 10px;

            .btn-consult-text {
                // display: none;
            }

            .btn-consult-icon {
                width: 40px;
            }
        }
    }
}