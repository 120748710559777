$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$darkTextColor: var(--primary-text-color);
$contentBackgroundColor: var(--white-color);
$successColor: var(--highlight-color);

$textColor: $baseColor4;
$titleLineColor: $baseColor3;

$primaryTextColor: var(--primary-text-color);

.starter-kit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3em;

    padding: 60px 0 2.5rem;

    background-color: $baseColor2;

    .starter-kit-title {
        font-weight: 700;
        font-family: 'Ubuntu';

        font-size: 3rem;
        margin-bottom: 2rem;
        border-bottom: 5px solid $titleLineColor;
        
        padding-bottom: 0.6rem;
        text-align: center;

        color: $baseColor4;

        img {
            width: 200px;
        }

        vertical-align: middle;
    }

    .starter-kit-cards {
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        // align-items: stretch;
        justify-content: space-evenly;

        padding: 0 2.5rem;

        .starter-kit-item {
            position: relative;
            border: 7px solid $baseColor4;
            
            &.is-best-seller {
                &::before {
                    content: "Terlaris";
                    text-align: center;

                    position: absolute;
                    top: -40px;

                    background-color: $baseColor1;
                    color: $baseColor4;
                    
                    min-width: 150px;
                    width: 50%;
                    
                    padding: 1rem;
                    border-radius: 10px;

                    font-weight: 700;
                    font-size: 20px;
                }

                border: 7px solid $baseColor1;
            }

            &.is-recommended {
                &::before {
                    content: "Pilihan Landack";
                    text-align: center;

                    position: absolute;
                    top: -40px;

                    background-color: $baseColor3;
                    color: $baseColor1;
                    
                    min-width: 150px;
                    width: fit-content;
                    
                    padding: 1rem;
                    border-radius: 10px;

                    font-weight: 700;
                    font-size: 20px;
                }

                border: 7px solid $baseColor3;
            }

            // background-color: $baseColor4;
            background-color: $contentBackgroundColor;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: first baseline;

            position: relative;

            min-width: 350px;
            width: 30%;
            // max-width: 550px;
            
            border-radius: 40px;
            
            padding: 0 1rem 1.5rem;

            .starter-kit-item-title {
                margin-bottom: 1rem;
                text-align: center;
                color: $baseColor1;
                font-weight: 700;
            }

            .starter-kit-item-desc {
                padding: 0 0.2rem;
                // color: $baseColor2;
                color: $darkTextColor;
            }

            .starter-kit-item-desc-list {
                padding: 0;

                & li {
                    list-style-type: none;
                }
            }

            .starter-kit-icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 70%;
                min-height: 325px;

                .starter-kit-item-icon {
                    width: 100%;
                    padding: 20px 0;
                    // margin-bottom: 0.8rem;
                }
            }

            .starter-kit-item-price {
                font-weight: 500;
                margin-top: 15px;
                // color: $baseColor1;
                
                .starter-kit-item-real-price {
                    // text-decoration: line-through;
                    font-weight: 700;
                }

                .starter-kit-item-discount-price {
                    color: $baseColor1;
                    font-size: 1.5rem;
                    font-weight: 700;

                    border-bottom: 3px solid $baseColor3;
                    line-height: 1.2rem;

                    margin: 1rem 0;
                }

                &.starter-kit-item-save-percentage {
                    font-size: 1.2rem;
                }
            }

            .btn-starter-kit {
                // position: absolute;
                // bottom: 30px;

                margin: 15px 0 20px;
                padding: 10px 20px;
                font-weight: 500;

                text-decoration: none;
                
                background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                -webkit-background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                -moz-background: linear-gradient(150deg, $baseColor1 0%, $baseColor2 100%);
                color: $textColor;

                border-radius: 20px;

                transition: all 0.5s ease-in-out;

                &:hover, &:focus, &:active {
                    background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                    -webkit-background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                    -moz-background: linear-gradient(150deg, $baseColor3 0%, $baseColor4 100%);
                    color: $primaryTextColor;
                }
            }
        }

        @media (max-width: 1200px) {
            .starter-kit-item {
                .starter-kit-icon-container {
                    min-height: 380px;
                }
            }
        }

        @media screen and (max-width: 860px) {
            gap: 3rem;
            
            .starter-kit-item {
                min-width: 0;
                max-width: 100%;
                width: 70%;

            }
        }

        @media screen and (max-width: 670px) {
            .starter-kit-item {
                width: 80%;
            }

            // padding: 0 2rem;
        }

        @media screen and (max-width: 568px) {
            .starter-kit-item {
                width: 100%;
            }

            padding: 0 2rem;
        }

        @media screen and (max-width: 430px) {
            .starter-kit-item {
                .starter-kit-item-desc-list {
                    .starter-kit-item-desc {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}