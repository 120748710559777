$titleLineColor: var(--base-primary-color-2);

$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$baseColorShade: var(--base-color-shade);

$backgroundColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);

.collaborator-section {
    background-color: $backgroundColor;
    padding: 120px 2rem 1rem;

    .collab-header {
        display: flex;
        align-items: center;
        justify-content: center;

        .collab-title {
            font-weight: 700;
            font-family: 'Ubuntu';
            font-size: 3.5rem;

            margin-bottom: 3rem;
            border-bottom: 5px solid $titleLineColor;
            padding-bottom: 0.6rem;
            
            color: $primaryTextColor;

            text-align: center;
        }

        @media screen and (max-width: 792px) {
            .collab-title {
                border: none;
            }
        }

        @media screen and (max-width: 670px) {
            .collab-title {
                font-size: 2.5rem;
            }
        }
    }

    .collab-partners {
        .partner-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: relative;

            margin-bottom: 30px;
            padding: 1rem;

            .partner-logo {
                width: 75%;

                img {
                    width: 100%;
                }
            }

            .partner-name {
                background-color: rgba(0, 0, 0, 0.7);
                font-size: 18px;
                color: $baseColor4;
                font-weight: 600;

                position: absolute;
                width: 95%;
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                opacity: 0;
                transition: opacity 0.2s linear; 
            }

            &:hover .partner-name {
                opacity: 1;
            }
        }
    }
}