$headerBackground: var(--header-background-image);
// $contentBackgroundColor: rgb(237, 255, 236);
$contentBackgroundColor: var(--white-color);
$highlightColor: var(--base-primary-color-2);
$primaryTextColor: var(--primary-text-color);

$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$headerBackgroundImage: var(--header-background-image);
$titleLineColor: $baseColor3;

.b2b-package-footer {
    width: 100%;
    display: flex;
    align-items: center;
    
    .b2b-package-footer-asset {
        width: 35%;
        min-width: 200px;

        img {
            width: 100%;
        }
    }

    .b2b-package-footer-content {
        font-family: 'Ubuntu';
        width: 60%;
        padding: 3rem;

        .footer-content-title {
            font-weight: 800;
            font-size: 2.5rem;
            // color: #edc953;
            color: $primaryTextColor;
            font-family: 'Ubuntu';
            text-align: right;
            
            margin-bottom: 20px;
            
            .highlighted {
                font-size: 3.5rem;
                text-shadow: 3px 3px 2px $baseColor2;
                display: block;
                color: $baseColor3;
                // text-decoration: underline;
                // text-decoration-color: $baseColor3;
            }
        }

        .footer-content-cta-button {
            max-width: 300px;
            float: right;
        }
    }
}

@media (max-width: 970px) {
    .b2b-package-footer {
        .b2b-package-footer-asset {
            min-width: 350px;
        }

        .b2b-package-footer-content {
            .footer-content-title {
                font-size: 2rem;

                .highlighted {
                    font-size: 2.5rem;
                }
            }

            .footer-content-description {
                font-size: 1.25rem;

            }
        }
    }
}

@media (max-width: 768px) {
    .b2b-package-footer {
        flex-direction: column-reverse;

        .b2b-package-footer-content {
            width: 100%;
            
            .footer-content-title {
                text-align: center;
            }

            .footer-content-cta-button {
                float: initial;
                max-width: unset;
                width: 100%;
            }
        }
    }
}