$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);
$backgroundColor: var(--base-color-shade);

.terms-and-condition {
    padding-top: 150px;

    width: 95%;
    margin: auto;

    &-intro {
        margin: 1.7rem auto 1.5rem;

        h1 {
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

        p {
            font-size: 17px;
        }
    }

    &-content {
        line-height: 1.7rem;

        ol {
            list-style-type: upper-latin;
            padding: 0 1.5rem;

            .terms-and-condition-content-item {
                margin-bottom: 2rem;

                & > li {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 60px;
                }
                
                .item-paragraph {
                    // margin: 0;
                }

                .item-lists-title {
                    margin-bottom: 0.5rem;
                }

                .item-lists {
                    margin-bottom: 1rem;
                    padding: 0 1.2rem;
                    list-style-type: decimal;
                    // list-style-type: disc;

                    & > .item-lists {
                        list-style-type: lower-latin;

                        & > .item-lists {
                            list-style-type: lower-roman;
                        }
                    }
                }
            }
        }

        .item-table {
            border-collapse: collapse;

            tr {
                th, td {
                    border: 1px solid black;
                    padding: 5px;
                }

                th {
                    font-weight: 700;
                    text-align: center;
                }
            }

            thead {
                tr {
                    th {
                        background-color: $baseColor1;
                        color: $baseColor4;
                    }

                    td {

                    }
                }

            }

            tbody {
                tr {
                    th {
                        background-color: $baseColor3;
                    }

                    td {
                        &.index-no {
                            background-color: $baseColor2;
                            color: $baseColor4;
                        }
                    }
                }
            }
        }
    }
}