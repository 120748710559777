$titleLineColor: var(--base-primary-color-2);

$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$baseColorShade: var(--base-color-shade);

$backgroundColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);

.faq-box {
    background-color: $backgroundColor;
    padding: 120px 2rem 3rem;

    .faq-header {
        display: flex;
        align-items: center;
        justify-content: center;

        .faq-title {
            font-weight: 700;
            font-family: 'Ubuntu';
            font-size: 3.5rem;

            margin-bottom: 3rem;
            border-bottom: 5px solid $titleLineColor;
            padding-bottom: 0.6rem;
            
            color: $primaryTextColor;

            text-align: center;
        }

        @media screen and (max-width: 792px) {
            .faq-title {
                border: none;
            }
        }

        @media screen and (max-width: 670px) {
            .faq-title {
                font-size: 2.5rem;
            }
        }
    }

    .question-group {
        row-gap: 2rem;
        column-gap: 1rem;
        justify-content: center;
        // justify-content: space-between;


        .question-item {
            border: 1px solid $baseColor2;
            // border-radius: 10px;
            cursor: pointer;
            overflow: hidden;

            .question-box {
                background-color: $baseColor2;
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                .accordion-button {
                    &:not(.collapsed) {
                        color: $baseColor4;
                        background-color: $baseColorShade;
                        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 $backgroundColor;
                        
                        &::after {
                            background-image: var(--bs-accordion-btn-icon);
                        }
                    }

                    &:hover {
                        background-color: $baseColor2;
                        color: $baseColor4;

                        &:after {
                            color: $baseColor4;
                        }
                    }

                }


                .question-title {
                    width: 80%;
                }
            }

            .question-content {
                background-color: $baseColorShade;

                a {
                    color: inherit;
                    transition: all 0.3s ease;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $baseColor2;
                    }
                }
            }
        }
    }
}